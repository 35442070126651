<template>
  <div class="partner-container">
    <h3 class="title">合作伙伴</h3>

    <swiper class="swiper" :options="swiperOption1">
      <swiper-slide
        class="swiper-item"
        v-for="(item, index) in swiper1"
        :key="item"
      >
        <img width="100%" height="100%" :src="partnerList[index * 3].picture" />
      </swiper-slide>
    </swiper>

    <swiper class="swiper" :options="swiperOption2">
      <swiper-slide
        class="swiper-item"
        v-for="(item, index) in swiper2"
        :key="item"
      >
        <img
          width="100%"
          height="100%"
          :src="partnerList[index * 3 + 1].picture"
        />
      </swiper-slide>
    </swiper>

    <swiper class="swiper" :options="swiperOption3">
      <swiper-slide
        class="swiper-item"
        v-for="(item, index) in swiper3"
        :key="item"
      >
        <img
          width="100%"
          height="100%"
          :src="partnerList[index * 3 + 2].picture"
        />
      </swiper-slide>
    </swiper>

    <div class="content">
      <div class="logo">
        <van-image
          width="100%"
          height="100%"
          fit="cover"
          lazy-load
          :src="require('@/assets/images/home/logo-2.png')"
        >
        </van-image>
      </div>
      <div class="introduction">
        <p>竞争的本质是模式的较量，</p>
        <p>竞争的本质是模式的较量，</p>
        <p>为您找到适宜企业的盈利模式！</p>
        <p>带您突破重围，一鸣惊人</p>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'Partner',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      partnerList: [
        {
          id: 1,
          picture: require('@/assets/images/home/partner-1.png')
        },
        {
          id: 2,
          picture: require('@/assets/images/home/partner-2.png')
        },
        {
          id: 3,
          picture: require('@/assets/images/home/partner-3.png')
        },
        {
          id: 4,
          picture: require('@/assets/images/home/partner-4.png')
        },
        {
          id: 5,
          picture: require('@/assets/images/home/partner-5.png')
        },
        {
          id: 6,
          picture: require('@/assets/images/home/partner-6.png')
        },
        {
          id: 7,
          picture: require('@/assets/images/home/partner-7.png')
        },
        {
          id: 8,
          picture: require('@/assets/images/home/partner-8.png')
        },
        {
          id: 9,
          picture: require('@/assets/images/home/partner-9.png')
        },
        {
          id: 10,
          picture: require('@/assets/images/home/partner-10.png')
        },
        {
          id: 11,
          picture: require('@/assets/images/home/partner-11.png')
        },
        {
          id: 12,
          picture: require('@/assets/images/home/partner-12.png')
        },
        {
          id: 13,
          picture: require('@/assets/images/home/partner-13.png')
        },
        {
          id: 14,
          picture: require('@/assets/images/home/partner-14.png')
        },
        {
          id: 15,
          picture: require('@/assets/images/home/partner-15.png')
        },
        {
          id: 16,
          picture: require('@/assets/images/home/partner-16.png')
        }
      ],
      swiperOption1: {
        slidesPerView: 'auto',
        spaceBetween: 7,
        loop: true,
        autoplay: {
          delay: 1000
        },
        centeredSlides: true
      },
      swiperOption2: {
        observer: true,
        observeParents: true,
        slidesPerView: 'auto',
        spaceBetween: 7,
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 1500
        }
      },
      swiperOption3: {
        slidesPerView: 'auto',
        spaceBetween: 7,
        loop: true,
        autoplay: {
          delay: 1200
        },
        centeredSlides: true
      }
    }
  },
  computed: {
    swiper1() {
      return this.partnerList.length % 3 >= 1
        ? parseInt(this.partnerList.length / 3) + 1
        : this.partnerList.length / 3
    },
    swiper2() {
      return this.partnerList.length % 3 >= 2
        ? parseInt(this.partnerList.length / 3) + 1
        : parseInt(this.partnerList.length / 3)
    },
    swiper3() {
      return parseInt(this.partnerList.length / 3)
    }
  }
}
</script>

<style lang="less" scoped>
.partner-container {
  padding-top: 43px;
  .title {
    font-size: 21px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    margin-bottom: 19px;
  }
  .swiper {
    margin-bottom: 7px;
  }
  .swiper-item {
    width: 110px;
    height: 110px;
  }

  .content {
    display: flex;
    width: 375px;
    height: 148px;
    background: #010343;
    padding-top: 18px;
    margin-top: 15px;
    .logo {
      width: 58px;
      height: 60px;
      margin-left: 40px;
    }
    .introduction {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      line-height: 25px;
      margin-left: 60px;
      margin-top: 7px;
    }
  }
}
</style>
