import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout'
import Home from '@/views/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: Home,
        meta: {
          title: '上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/course',
        component: () => import('@/views/Course'),
        meta: {
          title: '公司课程 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/course/fission',
        component: () => import('@/views/CourseFission'),
        meta: {
          title: '公司课程 - 裂变式盈利 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/course/land',
        component: () => import('@/views/CourseLand'),
        meta: {
          title: '公司课程 - 裂变落地系统 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/course/sale',
        component: () => import('@/views/CourseSale'),
        meta: {
          title: '公司课程 - 销讲大师计划 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/course/member',
        component: () => import('@/views/CourseMember'),
        meta: {
          title: '公司课程 - 会员模式 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/product',
        component: () => import('@/views/Product'),
        meta: {
          title: '公司产品 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/product/think',
        component: () => import('@/views/ProductThink'),
        meta: {
          title: '公司产品 - 智囊团 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/product/empower',
        component: () => import('@/views/ProductEmpower'),
        meta: {
          title: '公司产品 - 赋能计划 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/product/extension',
        component: () => import('@/views/ProductExtension'),
        meta: {
          title: '公司产品 - 拓站 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/case',
        component: () => import('@/views/Case'),
        meta: {
          title: '案例展示 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/case/detail/:id',
        component: () => import('@/views/CaseDetail'),
        meta: {
          title: '案例展示 - 案例详情 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/company',
        component: () => import('@/views/Company'),
        meta: {
          title: '关于我们 - 公司介绍 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/news/detail/:id',
        component: () => import('@/views/NewsDetail'),
        meta: {
          title: '新闻咨询 - 新闻详情 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/application/:id',
        component: () => import('@/views/Application'),
        meta: {
          title: '课程报名 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/lecturer',
        component: () => import('@/views/LecturerTeam'),
        meta: {
          title: '泉邦讲师团队 - 上海泉邦网络科技有限公司'
        }
      }
    ]
  },
  {
    path: '/news/news',
    component: () => import('@/views/MoreNews'),
    meta: {
      title: '新闻咨询 - 上海泉邦网络科技有限公司'
    }
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
