<template>
  <div class="cooperation-container">
    <h3 class="title">合作案例</h3>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="item in cooperationList" :key="item.id">
        <img :src="item.picture" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'Cooperation',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        effect: 'coverflow',
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        autoplay: {
          delay: 2000
        },
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 50,
          modifier: 1,
          slideShadows: true
        }
      },
      cooperationList: [
        {
          id: 1,
          picture: require('@/assets/images/home/cooperation_1.png')
        },
        {
          id: 2,
          picture: require('@/assets/images/home/cooperation_2.png')
        },
        {
          id: 3,
          picture: require('@/assets/images/home/cooperation_3.png')
        },
        {
          id: 4,
          picture: require('@/assets/images/home/cooperation_4.png')
        },
        {
          id: 5,
          picture: require('@/assets/images/home/cooperation_5.png')
        },
        {
          id: 6,
          picture: require('@/assets/images/home/cooperation_6.png')
        },
        {
          id: 7,
          picture: require('@/assets/images/home/cooperation_7.png')
        },
        {
          id: 8,
          picture: require('@/assets/images/home/cooperation_8.png')
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.cooperation-container {
  padding-top: 39px;
  .title {
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 31px;
  }
}
.swiper {
  width: 100%;
  height: 320px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  width: 200px;
  height: 282px;
  background: #ffffff;
  box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.1);
}
</style>
