<template>
  <div class="news-item-container">
    <div class="content">
      <h3 class="title van-ellipsis">{{ item.title }}</h3>
      <div class="time">{{ item.time }}</div>
      <div class="introduction van-multi-ellipsis--l2">
        {{ item.introduction }}
      </div>
      <van-image
        class="btn-img"
        :src="require('@/assets/images/company/company-btn.png')"
      >
      </van-image>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QbNewsItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.news-item-container {
  margin-top: 20px;
  .content {
    position: relative;
    width: 335px;
    height: 86px;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
    margin: 0 auto;
    padding: 10px 4px 5px 7px;
    .title {
      width: 294px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .time {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      opacity: 0.6;
    }
    .introduction {
      width: 279px;
      height: 30px;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      opacity: 0.6;
    }
    .btn-img {
      width: 38px;
      height: 39px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
