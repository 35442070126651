<template>
  <div class="footer-container">
    <div class="cell" @click="$router.push('/')">
      <div class="content">
        <h3 class="title">首页</h3>
      </div>
      <div class="line"></div>
    </div>
    <div class="cell" @click="$router.push('/product')">
      <div class="content">
        <h3 class="title">泉邦产品</h3>
        <span class="iconfont icon-jiahao icon"></span>
      </div>
      <div class="line"></div>
    </div>
    <div class="cell" @click="$router.push('/course')">
      <div class="content">
        <h3 class="title">泉邦课程</h3>
        <span class="iconfont icon-jiahao icon"></span>
      </div>
      <div class="line"></div>
    </div>
    <div class="cell" @click="$router.push('/case')">
      <div class="content">
        <h3 class="title">泉邦案例</h3>
        <span class="iconfont icon-jiahao icon"></span>
      </div>
      <div class="line"></div>
    </div>
    <div class="cell" @click="$router.push('/company')">
      <div class="content">
        <h3 class="title">关于我们</h3>
        <span class="iconfont icon-jiahao icon"></span>
      </div>
      <div class="line"></div>
    </div>

    <div class="copyright">
      Copyright ©2019 - 2020 上海泉邦网络科技有限公司<br />
      <a
        rel="nofollow"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
      >
        <span>沪ICP备17000895号-6</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: ''
}
</script>

<style lang="less" scoped>
.footer-container {
  width: 375px;
  height: 372px;
  background: #f7f9fd;
  padding-top: 26px;
}
.cell {
  padding: 14px 19px 0 21px;
  .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
      letter-spacing: 1px;
    }
    .icon {
      font-weight: 700;
      vertical-align: text-bottom;
    }
  }
  .line {
    width: 335px;
    opacity: 0.35;
    border-bottom: 2px solid #222222;
  }
}
.copyright {
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  text-align: center;
  margin-top: 27px;
  a {
    color: #000000;
  }
}
</style>
