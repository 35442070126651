<template>
  <div class="navbar-container">
    <van-nav-bar left-arrow @click-left="onClickLeft" fixed z-index="9999">
      <template #title>
        <div>
          {{ title }}
        </div>
      </template>
      <template #left>
        <div class="back" @click="$router.go(-1)">
          <span class="iconfont icon-fanhui"></span>
        </div>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  name: 'QbNavbar',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    onClickLeft() {}
  }
}
</script>

<style lang="less" scoped>
.navbar-container {
  height: 48px;
}
::v-deep .van-nav-bar__content {
  height: 48px;
  img {
    width: 113px;
    height: 34px;
  }
}
::v-deep .van-nav-bar__left {
  padding: 0 21px;
}
::v-deep .van-nav-bar__title {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}

.back {
  width: 27px;
  height: 27px;
  line-height: 27px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.18);
  border-radius: 50%;
  span {
    font-size: 14px;
    font-weight: 700;
  }
}
</style>
