<template>
  <div
    class="more"
    :style="{ marginTop: top + 'px', marginBottom: bottom + 'px' }"
  >
    <van-button color="#010343" class="btn">
      MORE <i class="iconfont icon-xiangyou"></i>
    </van-button>
  </div>
</template>

<script>
export default {
  name: 'More',
  props: {
    top: {
      type: String
    },
    bottom: {
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.more {
  display: flex;
  justify-content: center;
  .btn {
    width: 105px;
    height: 35px;
    background: #010343;
    border-radius: 4px;
  }
}
</style>
