<template>
  <div class="banner-container">
    <!-- 视频 -->
    <div class="video">
      <video
        src="http://res.quanbangcloud.com/videos/00/0002a3dff6f443a782e3b8c740bcbe76.mp4"
        poster="@/assets/images/video_cover.png"
        autoplay="autoplay"
        muted="muted"
        loop="loop"
      ></video>
      <!-- 遮罩 -->
      <div class="mask"></div>
      <!-- 轮播图 -->
      <van-swipe
        :autoplay="autoplay"
        vertical
        :touchable="isTouchable"
        class="swiper"
        @change="handleChangeSwiper"
      >
        <van-swipe-item v-for="(item, index) in courseList" :key="item.id">
          <div class="content">
            <div class="title">
              {{ item.title }}
              <span
                class="icon iconfont icon-Rbiao"
                v-if="item.title === '智囊团 陪跑计划'"
              ></span>
            </div>
            <div class="introduction" v-html="item.introduction"></div>
            <div class="money">RMB {{ item.money }} 元</div>
            <van-button round @click="$router.push(`${navigateList[index]}`)"
              >立即参与</van-button
            >
          </div>
        </van-swipe-item>
        <template #indicator>
          <ul class="custom-indicator">
            <li
              :class="{ active: item.id === currentIndex + 1 }"
              v-for="item in courseList"
              :key="item.id"
            ></li>
          </ul>
        </template>
      </van-swipe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  data() {
    return {
      courseList: [
        {
          id: 1,
          introduction: '一对多认知赋能 企业家必修课程',
          title: '裂变式盈利',
          money: '1,980'
        },
        {
          id: 2,
          introduction: '一对一方案辅导 小班制烧脑干货',
          title: '裂变落地系统',
          money: '39,800'
        },
        {
          id: 3,
          introduction: '底层设计 迅速吸金 简单粗暴 行之有效',
          title: '会员模式',
          money: '12,800'
        },
        {
          id: 4,
          introduction:
            '选对正确赛道 全程加速陪跑<br/>智囊倾力相助 迅速落地见效',
          title: '智囊团 陪跑计划',
          money: '338,000'
        }
      ],
      currentIndex: 0,
      isTouchable: true,
      autoplay: 3000,
      navigateList: [
        '/course/fission',
        '/course/member',
        '/product/empower',
        '/course/sale'
      ]
    }
  },
  methods: {
    handleChangeSwiper(index) {
      this.currentIndex = index
      if (index === 3) {
        this.isTouchable = false
        this.autoplay = 8000
      } else {
        this.isTouchable = true
        this.autoplay = 3000
      }
    }
  }
}
</script>

<style lang="less" scoped>
.banner-container {
  width: 375px;
  height: 667px;
  position: relative;
  overflow: hidden;
}
video {
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: 998;
  width: 100%;
  height: 677px;
  background-color: #fff;
}
.mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 667px;
  width: 375px;
  background-color: #0d2f80;
  opacity: 0.3;
  z-index: 999;
}
.swiper {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  height: 667px;
  width: 375px;
  .content {
    margin-top: 251px;
    margin-left: 20px;
  }
  .enTitle {
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
  }

  .title {
    position: relative;
    color: #ffffff;
    line-height: 50px;
    font-size: 36px;
    margin-bottom: 29px;
    span {
      position: absolute;
      left: 106px;
      top: -10px;
    }
  }

  .introduction {
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .money {
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
    margin-bottom: 22px;
  }
  ::v-deep .van-button {
    width: 138px;
    height: 36px;
    font-size: 18px;
    font-weight: 400;
    color: #010343;
  }
  .custom-indicator {
    position: absolute;
    left: 336px;
    top: 328px;
    li {
      width: 11px;
      height: 11px;
      background: #ffffff;
      border-radius: 50%;
      margin-bottom: 11px;
      transition: all 0.3s;
      &.active {
        width: 11px;
        height: 27px;
        background: #ffffff;
        border-radius: 6px;
      }
    }
  }
}
</style>
