<template>
  <div class="application-container">
    <h3 class="title">课程报名</h3>

    <van-tabs v-model="active">
      <van-tab
        :title="item.month"
        v-for="(item, index) in monthSessionList"
        :key="index"
      >
        <ApplicationSessionItem
          v-for="session in item.sessionList"
          :key="session.id"
          :session="session"
          @click.native="$router.push(`/application/${session.id}`)"
        ></ApplicationSessionItem>
      </van-tab>
    </van-tabs>
    <div class="line"></div>
  </div>
</template>

<script>
import ApplicationSessionItem from './application-session-item.vue'
export default {
  name: 'application',
  components: {
    ApplicationSessionItem
  },
  data() {
    return {
      active: 0,
      monthSessionList: [
        {
          month: '2021年12月',
          sessionList: [
            {
              id: 1,
              picture: require('@/assets/images/home/fission-cover.png'),
              courseName: '裂变式盈利',
              lecturer: '马煜超老师',
              address: '上海',
              time: '2021年12月27-29号'
            }
          ]
        },
        {
          month: '2021年1月',
          sessionList: [
            {
              id: 2,
              picture: require('@/assets/images/home/land-cover.png'),
              courseName: '裂变落地系统',
              lecturer: '马煜超老师',
              address: '上海',
              time: '2021年1月04-06号'
            },
            {
              id: 3,
              picture: require('@/assets/images/home/fission-cover.png'),
              courseName: '裂变式盈利',
              lecturer: '马煜超老师',
              address: '上海',
              time: '2021年1月11-13号'
            },
            {
              id: 4,
              picture: require('@/assets/images/home/fission-cover.png'),
              courseName: '裂变式盈利',
              lecturer: '马煜超老师',
              address: '上海',
              time: '2021年1月17-19号'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.application-container {
  position: relative;
  padding-bottom: 54px;
  .title {
    font-size: 21px;
    text-align: center;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 9px;
  }
  .line {
    width: 375px;
    position: absolute;
    border-bottom: 1px solid #3f3f3f;
    top: 67px;
  }
  ::v-deep .van-tabs__wrap {
    height: 30px;
    .van-tabs__nav {
      justify-content: space-evenly;
    }
    .van-tab {
      padding: 0;
      flex: none;
    }
    .van-tabs__line {
      width: 96px;
      height: 3px;
      background: #010343;
    }

    .van-tab--active {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 700;
      color: #010343;
    }
  }
}
</style>
