<template>
  <div class="layout-container">
    <!-- 二级路由出口 -->
    <router-view></router-view>
    <!-- tabbar -->
    <van-tabbar z-index="8888" v-model="active" route>
      <van-tabbar-item to="/">
        <span>首页</span>
        <template #icon="props">
          <img
            :src="props.active ? home.active : home.inactive"
            class="icon1"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/course">
        <span>课程</span>
        <template #icon="props">
          <img
            :src="props.active ? course.active : course.inactive"
            class="icon1"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/product">
        <span>产品</span>
        <template #icon="props">
          <img
            :src="props.active ? product.active : product.inactive"
            class="icon2"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/case">
        <span>案例</span>
        <template #icon="props">
          <img
            :src="props.active ? case1.active : case1.inactive"
            class="icon1"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/company">
        <span>公司</span>
        <template #icon="props">
          <img
            :src="props.active ? company.active : company.inactive"
            class="icon2"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './components/footer.vue'
export default {
  name: 'Layout',
  data() {
    return {
      active: 0,
      home: {
        active: require('@/assets/images/layout/home-active.png'),
        inactive: require('@/assets/images/layout/home.png')
      },
      course: {
        active: require('@/assets/images/layout/course-active.png'),
        inactive: require('@/assets/images/layout/course.png')
      },
      product: {
        active: require('@/assets/images/layout/product-active.png'),
        inactive: require('@/assets/images/layout/product.png')
      },
      case1: {
        active: require('@/assets/images/layout/case-active.png'),
        inactive: require('@/assets/images/layout/case.png')
      },
      company: {
        active: require('@/assets/images/layout/company-active.png'),
        inactive: require('@/assets/images/layout/company.png')
      }
    }
  },
  components: {
    Footer
  }
}
</script>

<style lang="less" scoped>
.layout-container {
  padding-bottom: 72px;
  .van-tabbar-item--active {
    color: #1651dd;
  }
}

::v-deep .van-tabbar {
  height: 72px;
  .van-tabbar-item__icon {
    .icon1 {
      width: 17.3px;
      height: 20px;
    }
    .icon2 {
      width: 20.7px;
      height: 20px;
    }
  }
}
</style>
