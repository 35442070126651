<template>
  <div class="qb-application-container">
    <van-form class="form" @submit="onSubmit" ref="form">
      <van-field
        v-model="formData.username"
        type="text"
        name="username"
        placeholder="请输入您的姓名"
        left-icon="user-o"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="formData.phoneNumber"
        type="text"
        name="phoneNumber"
        placeholder="点击输入手机号/电话"
        left-icon="phone-o"
        :rules="[{ required: true, message: '请填写手机号/电话' }]"
      />
      <van-field
        v-model="formData.companyName"
        type="text"
        name="公司名称"
        placeholder="点击输入公司名称"
        left-icon="hotel-o"
        :rules="[{ required: true, message: '请填写公司名称' }]"
      />
      <van-field
        v-model="formData.companyAddress"
        type="text"
        name="companyAddress"
        placeholder="请输入公司地址"
        left-icon="location-o"
        :rules="[{ required: true, message: '请填写公司地址' }]"
      />
      <van-field
        readonly
        clickable
        name="session"
        :value="formData.session"
        placeholder="点击选择课程场次"
        @click="showPicker = true"
        right-icon="arrow"
        left-icon="clock-o"
      >
      </van-field>
      <div style="margin: 16px;">
        <van-button
          class="btn"
          round
          block
          color="#010343"
          type="info"
          native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'qbApplication',
  data() {
    return {
      formData: {
        username: '',
        phoneNumber: '',
        companyName: '',
        companyAddress: '',
        session: ''
      },
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      showPicker: false
    }
  },
  methods: {
    async onSubmit(values) {
      console.log(this.$refs.form)
    },
    onConfirm(value) {
      this.formData.session = value
      this.showPicker = false
    }
  }
}
</script>

<style lang="less" scoped>
.qb-application-container {
  padding: 50px 20px;
  .form {
    width: 335px;
    padding: 36px 38px 28px 45px;
    background: #ffffff;
    box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.1);
  }

  ::v-deep .van-form {
    .van-cell {
      border-bottom: 1px solid #d8d8d8;
    }
    .van-field__control {
      padding-left: 20px;
      font-weight: 400;
      color: #333333;
    }
    .van-button--round {
      margin-top: 20px;
      border-radius: 4px;
    }
  }
}
</style>
