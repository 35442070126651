<template>
  <div class="main-course-container">
    <div class="title">主营课程</div>
    <div class="subTitle">打造商业模式，让商业模式为您盈利</div>
    <van-tabs v-model="active">
      <van-tab title="裂变落地系统">
        <!-- <van-swipe :autoplay="3000" class="swiper">
          <van-swipe-item v-for="(image, index) in images" :key="index">
            <van-image width="100%" height="100%" lazy-load :src="image" />
          </van-swipe-item>
        </van-swipe> -->
        <van-image
          width="100%"
          height="100%"
          lazy-load
          :src="require('@/assets/images/home/course-land.png')"
        />
      </van-tab>
      <van-tab title="裂变式盈利">
        <van-image
          width="100%"
          height="100%"
          lazy-load
          :src="require('@/assets/images/home/course-fission.png')"
        />
      </van-tab>
      <van-tab title="会员模式">
        <van-image
          width="100%"
          height="100%"
          lazy-load
          :src="require('@/assets/images/home/course-member.png')"
        />
      </van-tab>
      <van-tab title="赋能计划">
        <van-image
          width="100%"
          height="100%"
          lazy-load
          :src="require('@/assets/images/home/course-empower.png')"
        />
      </van-tab>
    </van-tabs>
    <div class="line"></div>
    <QbMore top="7" @click.native="goCourse"></QbMore>
  </div>
</template>

<script>
import QbMore from '@/components/qb-more.vue'
export default {
  name: 'MainCourse',
  components: {
    QbMore
  },
  data() {
    return {
      active: 0,
      navigatorList: [
        '/course/land',
        '/course/fission',
        '/course/member',
        '/course/sale'
      ]
    }
  },
  methods: {
    goCourse() {
      this.$router.push(this.navigatorList[this.active])
    }
  }
}
</script>

<style lang="less" scoped>
.main-course-container {
  position: relative;
  padding-bottom: 18px;
  .title {
    font-size: 21px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 7px;
  }
  .subTitle {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 17px;
    opacity: 0.42;
    text-align: center;
    margin-bottom: 18px;
  }
  .line {
    width: 375px;
    position: absolute;
    border-bottom: 1px solid #3f3f3f;
    top: 99px;
  }
  ::v-deep .van-tabs__wrap {
    height: 30px;
    .van-tabs__nav {
      justify-content: space-evenly;
    }
    .van-tab {
      padding: 0;
      flex: none;
    }
    .van-tabs__line {
      width: 96px;
      height: 3px;
      background: #010343;
    }

    .van-tab--active {
      font-size: 16px;
      font-weight: 700;
      color: #010343;
    }
  }
  .swiper {
    width: 375px;
    height: 462px;
    img {
      width: 100%;
    }
    ::v-deep .van-swipe__indicators {
      .van-swipe__indicator {
        position: relative;
        width: 6px;
        height: 6px;
        border: 1px solid #010343;
        background-color: #fff;
        opacity: 1;
      }
      i.van-swipe__indicator--active {
        width: 12px;
        height: 12px;
        background: #000;
        border: 1px solid #010343;
        transform: translateY(-20%);
      }
    }
  }
}
</style>
