<template>
  <div class="home-container">
    <!-- navbar -->
    <HomeNavbar></HomeNavbar>
    <!-- banner -->
    <HomeBanner></HomeBanner>
    <!-- main-course -->
    <HomeMainCourse></HomeMainCourse>
    <!-- lecturer -->
    <HomeLecturer></HomeLecturer>
    <!-- partner -->
    <HomePartner></HomePartner>
    <!-- cooperation -->
    <Cooperation></Cooperation>
    <!-- application -->
    <Application></Application>
  </div>
</template>

<script>
import HomeNavbar from './components/home-navbar.vue'
import HomeBanner from './components/home-banner.vue'
import HomeMainCourse from './components/home-main-course.vue'
import HomeLecturer from './components/home-lecturer.vue'
import HomePartner from './components/home-partner.vue'
import Cooperation from './components/home-cooperation.vue'
import Application from './components/home-application.vue'
export default {
  name: 'Home',
  components: {
    HomeNavbar,
    HomeBanner,
    HomeMainCourse,
    HomeLecturer,
    HomePartner,
    Cooperation,
    Application
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.home-container {
  padding-top: 46px;
}
</style>
