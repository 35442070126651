import Vue from 'vue'
import {
  Tabbar,
  TabbarItem,
  NavBar,
  Swipe,
  SwipeItem,
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Icon,
  Image as VanImage,
  Pagination,
  List,
  PullRefresh,
  Form,
  Field,
  Popup,
  Picker,
  Col,
  Row,
  Lazyload
} from 'vant'

Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Icon)
Vue.use(VanImage)
Vue.use(Pagination)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Form)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Col)
Vue.use(Row)
Vue.use(Lazyload)
