<template>
  <div class="lecturer-container">
    <h3 class="title">泉邦讲师</h3>

    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        class="swiper-item"
        v-for="item in lecturerList"
        :key="item.id"
      >
        <img :src="item.picture" alt="" />
      </swiper-slide>
    </swiper>

    <QbMore top="45" @click.native="$router.push('/lecturer')"></QbMore>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import QbMore from '@/components/qb-more.vue'
export default {
  name: 'Lecturer',
  components: {
    swiper,
    swiperSlide,
    QbMore
  },
  data() {
    return {
      lecturerList: [
        {
          id: 1,
          picture: require('@/assets/images/lecturer/mentor_1.png')
        },
        {
          id: 2,
          picture: require('@/assets/images/lecturer/mentor_2.png')
        },
        {
          id: 3,
          picture: require('@/assets/images/lecturer/mentor_3.png')
        },
        {
          id: 4,
          picture: require('@/assets/images/lecturer/mentor_4.png')
        },
        {
          id: 5,
          picture: require('@/assets/images/lecturer/mentor_5.png')
        },
        {
          id: 6,
          picture: require('@/assets/images/lecturer/mentor_6.png')
        },
        {
          id: 7,
          picture: require('@/assets/images/lecturer/mentor_7.png')
        },
        {
          id: 8,
          picture: require('@/assets/images/lecturer/mentor_8.png')
        },
        {
          id: 9,
          picture: require('@/assets/images/lecturer/mentor_9.png')
        }
      ],
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 1000
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.lecturer-container {
  background-color: #f7f7f7;
  padding-top: 68px;
  padding-bottom: 31px;
  .title {
    font-size: 21px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    margin-bottom: 39px;
  }
  .swiper-item {
    width: 260px;
    height: 412px;
    background: #00123f;
  }
}
</style>
