<template>
  <div class="navbar-container">
    <van-nav-bar>
      <template #title>
        <img src="@/assets/images/home/logo.png" alt="" />
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  name: 'Navbar'
}
</script>

<style lang="less" scoped>
.navbar-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1001;
  ::v-deep .van-nav-bar {
    line-height: 0;
  }

  ::v-deep .van-nav-bar__content {
    height: 48px;
    img {
      width: 113px;
      height: 34px;
    }
  }
  img {
    width: 113px;
    height: 34px;
  }
}
</style>
