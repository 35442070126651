<template>
  <div class="block2 title-container">
    <span class="word2">{{ title }}</span>
    <span class="word3">{{ subtitle }}</span>
  </div>
</template>

<script>
export default {
  name: 'QbTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.block2 {
  z-index: 38;
  height: 99px;
  background-color: rgba(247, 249, 253, 1);
  width: 375px;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  padding: 0 28px 4px 0;
}

.word3 {
  z-index: 41;
  position: absolute;
  left: 50px;
  top: 26px;
  font-weight: 700;
  height: 66px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 0.04);
  font-size: 36px;
  line-height: 33px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word2 {
  z-index: 40;
  position: absolute;
  left: 28px;
  top: 5px;
  width: 294px;
  height: 64px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 36px;
  white-space: nowrap;
  line-height: 64px;
  font-weight: 700;
  text-align: justify;
}
</style>
